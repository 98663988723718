@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:200,300,400,500,600,700,800&display=swap");
@import "material-symbols";

@import "themes";

$spacer: 1rem;

$grid-gutter-width: 1.5rem;
$gutters: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);

@import "@nebular/theme/styles/globals";

@import "./material/angular-material";

@import "./pace.theme";

@import "./layout";
@import "./overrides";
@import "./material/material-overrides";

@import "./bootstrap-w-h.scss";

@include nb-install() {
  @include angular-material();

  @include nb-theme-global();

  @include ngx-layout();
  @include ngx-pace-theme();

  @include nb-overrides();
  @include material-overrides();
}

nb-menu .menu-item a.active .menu-icon {
  fill: var(--menu-item-icon-active-color); // fill menu icon color
}

nb-sidebar.expanded nb-menu .menu-item {
  position: relative;
}
// nb-sidebar nb-menu .menu-item {
//   padding-left: 1rem;
//   padding-right: 1rem;
// }

nb-sidebar.expanded nb-menu .menu-item a {
  height: 3.5rem;
}

nb-sidebar.compacted nb-menu .menu-item a {
  height: 3.5rem;
}

nb-sidebar.expanded nb-menu .menu-item a.active::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 4px;
  background: var(--sidebar-menu-item-highlight-color);
}

.device-status-circle {
  height: 19px;
  width: 19px;
  border-radius: 50%;
  display: block;
}

.aqi-color-circle {
  height: 19px;
  width: 26px;
  border-radius: 50%;
  display: block;
}

.cursor-pointer {
  cursor: pointer;
}

.widget-fields-chip-container {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  font-size: 8pt !important;
  &::-webkit-scrollbar {
    display: none;
  }
}

.widget-field-chip {
  display: inline-block;
  padding: 0.1rem 0.6rem;
  background: var(--chip-color);
  border: 1px solid var(--chip-color);
  cursor: pointer;
  margin-right: 0.5rem;
  border-radius: 1rem;
  &.active {
    transform: scale(1.05);
    font-weight: 500;
    background: var(--selected-chip-color);
    border: 1px solid var(--selected-chip-border-color);
  }
}

.widget-fields-chip-wrapper {
  container-type: inline-size;

  @container (max-width: 550px) {
    .arrow-left-wrapper,
    .arrow-right-wrapper {
      .mat-icon {
        font-size: 1rem;
        width: 1rem;
        height: 1rem;
      }
    }

    .widget-field-chip {
      padding: 0.05rem 0.3rem;
      font-size: 0.8rem;
      margin-right: 0.25rem;
    }
  }
}

.mtx-drawer-content-wrapper {
  padding: 0 !important;
}

.border-bottom {
  border: 1px solid var(--border-bottom-color) !important;
}

.row-selected {
  background-color: var(--selected-table-row-background-color) !important;
}

.mdc-data-table__row:not(.row-selected):hover {
  background-color: var(--hover-table-row-background-color) !important;
}

.text-basic-color {
  color: var(--text-basic-color);
}

nb-sidebar.compacted nb-menu .menu-items > .menu-item a {
  justify-content: left;
}

.form-stepper {
  margin-top: -1rem;
}

::ng-deep.nb-theme-material-light {
  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__checkmark {
    color: white !important;
  }
}

::ng-deep .nb-theme-material-dark {
  .mat-mdc-fab .mat-icon,
  .mat-mdc-fab .material-icons,
  .mat-mdc-mini-fab .mat-icon,
  .mat-mdc-mini-fab .material-icons {
    color: black !important;
  }
}

.backDrop {
  background-color: rgba(255, 255, 255, 0.048);
  backdrop-filter: blur(1px);
}

.mat-mdc-snack-bar-container {
  &.snackbar-error {
    --mdc-snackbar-container-color: rgba(241, 174, 169);
    border: 2px solid rgba(240, 22, 6);
    --mat-mdc-snack-bar-button-color: #000;
    --mdc-snackbar-supporting-text-color: #000;
    border-radius: 4px;
    --mdc-snackbar-supporting-text-weight: 500;
    --mdc-snackbar-supporting-text-size: 16px !important;
    --mdc-snackbar-supporting-font-family: Roboto !important;

    &::before {
      content: "error";
      font-family: "Material Icons";
      color: rgba(240, 22, 6);
      font-size: 24px;
      margin-right: 8px;
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }

    .mat-mdc-snack-bar-label {
      margin-left: 17px;
      display: inline-block;
      z-index: 0;
    }
  }
}

.mat-mdc-snack-bar-container {
  &.snackbar-success {
    --mdc-snackbar-container-color: rgba(157, 240, 162);
    border: 2px solid rgba(3, 144, 8);
    --mat-mdc-snack-bar-button-color: #000;
    --mdc-snackbar-supporting-text-color: #000;

    border-radius: 4px;
    --mdc-snackbar-supporting-text-weight: 500;
    --mdc-snackbar-supporting-text-size: 16px !important;
    --mdc-snackbar-supporting-font-family: Roboto !important;

    &::before {
      content: "check_circle";
      font-family: "Material Icons";
      color: rgba(3, 144, 8);
      font-size: 24px;
      margin-right: 8px;
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }

    .mat-mdc-snack-bar-label {
      margin-left: 17px;
      display: inline-block;
      z-index: 0;
    }
  }
}

.mat-mdc-snack-bar-container {
  &.snackbar-warning {
    --mdc-snackbar-container-color: rgba(234, 212, 153);
    border: 2px solid rgba(204, 123, 66);
    --mat-mdc-snack-bar-button-color: #000;
    --mdc-snackbar-supporting-text-color: #000;
    border-radius: 4px;
    --mdc-snackbar-supporting-text-weight: 500;
    --mdc-snackbar-supporting-text-size: 16px !important;
    --mdc-snackbar-supporting-font-family: Roboto !important;

    &::before {
      content: "warning";
      font-family: "Material Icons";
      color: rgba(204, 123, 66);
      font-size: 24px;
      margin-right: 8px;
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }

    .mat-mdc-snack-bar-label {
      margin-left: 17px;
      display: inline-block;
      z-index: 0;
    }
  }
}

.mat-mdc-snack-bar-container {
  &.snackbar-info {
    --mdc-snackbar-container-color: rgba(160, 228, 244);
    border: 2px solid rgba(32, 169, 246);
    --mat-mdc-snack-bar-button-color: #000;
    --mdc-snackbar-supporting-text-color: #000;
    border-radius: 4px;
    --mdc-snackbar-supporting-text-weight: 500;
    --mdc-snackbar-supporting-text-size: 16px !important;
    --mdc-snackbar-supporting-font-family: Roboto !important;

    &::before {
      content: "info";
      font-family: "Material Icons";
      color: rgba(32, 169, 246);
      font-size: 24px;
      margin-right: 8px;
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }

    .mat-mdc-snack-bar-label {
      margin-left: 17px;
      display: inline-block;
      z-index: 0;
    }
  }
}

// .mat-mdc-snack-bar-container {
//   &.snackbar-error {
//     --mdc-snackbar-container-color: rgba(244, 67, 54, 0.8);
//     --mat-mdc-snack-bar-button-color: #fff;
//     --mdc-snackbar-supporting-text-color: #fff;

//     display: flex;
//     align-items: center;
//     padding: 16px;
//     position: relative;

//     &::before {
//       content: 'error';
//       font-family: 'Material Icons';
//       color: rgba(244, 67, 54, 0.8);
//       font-size: 24px;
//       margin-right: 12px;
//       line-height: 1;
//     }
//   }
// }

.toast-container {
  max-height: calc(100% - 24px);
  overflow: auto;
  scrollbar-width: thin;
}

.material-symbols-filled {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
}

.icon-with-label {
  font-size: 18px !important;
  padding-bottom: 5px;
}

.transparent-icon-with-label {
  font-size: 18px !important;
  color: transparent;
}

::ng-deep.option-icon-with-label {
  .mdc-list-item__primary-text {
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    gap: 8px !important;
  }
}
// @media (min-width: 992px) {
//   .col-lg-5 {
//       flex: 0 0 auto;
//       width: 37%;
//   }
// }
@media (min-width: 992px) {
  .col-lg-3 {
      flex: 0 0 auto;
      width: 23%;
  }
}

@media (min-width: 992px) {
  .col-lg-2 {
      flex: 0 0 auto;
      width: 19%;
  }
}

.arrow-icon{
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
}

::ng-deep.mat-mdc-checkbox {
  .mdc-checkbox {
    // padding: 0;

    .mdc-checkbox__background {
      top: 0;
      left: 0;
    }

    .mdc-checkbox__native-control {
      top: 0;
      left: 0;
      right: auto;
      height: 18px;
      width: 18px;
    }
  }

  .mat-mdc-checkbox-touch-target {
    height: 18px;
    width: 18px;
  }
}
::ng-deep .mat-button-toggle-button .mat-button-toggle-appearance-standard{
  background: var(--background-basic-color-1) !important;
}


.mdc-menu-surface.mat-mdc-autocomplete-panel {
  background: var(--background-basic-color-1) !important;
}

.mat-mdc-menu-content{
  background: var(--background-basic-color-1) !important;
}