@use "@angular/material" as mat;
@use "@ng-matero/extensions" as mtx;
@import "@angular/material/theming";

@import "./../palettes.scss";

@mixin angular-material() {
  @include mat.core();

  $light-primary-palette-d: mat.define-palette($light-primary-palette, 500);
  $light-secondary-palette-d: mat.define-palette($light-secondary-palette, 500);

  $angular-default-theme: mat.define-light-theme(
    (
      color: (
        primary: $light-primary-palette-d,
        accent: $light-secondary-palette-d,
      ),
      typography: mat.define-typography-config(),
      density: 0,
    )
  );

  .nb-theme-material-light,
  .nb-theme-material-dark,
  .nb-theme-material-light-trojena,
  .nb-theme-default,
  .nb-theme-dark {
    @include mat.all-component-themes($angular-default-theme);
    @include mtx.all-component-themes($angular-default-theme);
  }

  .nb-theme-material-light .mat-primary.mat-mdc-raised-button:not(:disabled) {
    color: $light-text;
  }

  .nb-theme-material-light-trojena .mat-primary.mat-mdc-raised-button:not(:disabled) {
    color: $light-text;
  }

  .nb-theme-material-dark .mat-mdc-unelevated-button:not(:disabled) {
    color: $light-text !important;
    background-color: transparent;
  }

  mat-mdc-fab,
  .mat-mdc-mini-fab {
    color: $light-text !important;
  }

  $dark-primary-palette-d: mat.define-palette($dark-primary-palette, 500);
  $dark-secondary-palette-d: mat.define-palette($dark-secondary-palette, 500);

  $angular-dark-theme: mat.define-dark-theme(
    (
      color: (
        primary: $dark-primary-palette-d,
        accent: $dark-secondary-palette-d,
      ),
      typography: mat.define-typography-config(),
      density: 0,
    )
  );

  .nb-theme-material-dark,
  .nb-theme-dark {
    @include mat.all-component-colors($angular-dark-theme);
  }
}
.text-primary {
  color: var(--color-primary-default) !important;
}

.bg-primary {
  background-color: var(--color-primary-default) !important;
}

mat-form-field {
  .mat-mdc-form-field-infix {
    line-height: 24px;
  }
}

.mat-drawer {
  background-color: var(--drawer-background-color) !important;
}
