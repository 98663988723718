$light-text: #ffffff;
$dark-text: #000000;

$light-primary-50: #DDF8F5;
$light-primary-100: #DDF8F5;
$light-primary-200: #D4F6F2;
$light-primary-300: #92E9DC;
$light-primary-400: #03DAC5; // primary light (light theme)
$light-primary-500: #00C4B4; // primary main (light theme)
$light-primary-600: #00B3A6; // primary dark (light theme)
$light-primary-700: #00A394;
$light-primary-800: #009181;
$light-primary-900: #008574;
$light-primary-a100: #CCFFF7;
$light-primary-a200: #99FFEF;
$light-primary-a400: #66FFE8;
$light-primary-a700: #4DFFE4;


$light-secondary-50: #a9afb5;
$light-secondary-100: #a9afb5;
$light-secondary-200: #7f858a;
$light-secondary-300: #6b7075;
$light-secondary-400: #4B5055; // secondary light (light theme)
$light-secondary-500: #2A3037; // secondary main (light theme)
$light-secondary-600: #191D22; // secondary dark (light theme)
$light-secondary-700: #131619;
$light-secondary-800: #121417;
$light-secondary-900: #080909;
$light-secondary-a100: #5BBDFF;
$light-secondary-a200: #28A9FF;
$light-secondary-a400: #0092F4;
$light-secondary-a700: #0083DA;



$dark-primary-50: #E9FFFB;
// $dark-primary-100: #E9FFFB;
$dark-primary-100: #465452;
$dark-primary-200: #E9FFFB;
$dark-primary-300: #E9FFFB;
$dark-primary-400: #C8FFF4; // primary light (dark theme)
$dark-primary-500: #70EFDE; // primary main (dark theme)
$dark-primary-600: #03DAC5; // primary dark (dark theme)
$dark-primary-700: #00c7ab;
$dark-primary-800: #00b798;
$dark-primary-900: #00a885;
$dark-primary-a100: #FFFFFF;
$dark-primary-a200: #FFFFFF;
$dark-primary-a400: #CFFFF6;
$dark-primary-a700: #B5FFF2;


$dark-secondary-50: #F3E5F5;
$dark-secondary-100: #F3E5F5;
$dark-secondary-200: #F3E5F5;
$dark-secondary-300: #F3E5F5;
$dark-secondary-400: #F3E5F5; // secondary light (dark theme)
$dark-secondary-500: #CE93D8; // secondary main (dark theme)
$dark-secondary-600: #AB47BC; // secondary dark (dark theme)
$dark-secondary-700: #9b27b0;
$dark-secondary-800: #8d24aa;
$dark-secondary-900: #7a1fa2;
$dark-secondary-a100: #FFFFFF;
$dark-secondary-a200: #FEFCFF;
$dark-secondary-a400: #F6C9FF;
$dark-secondary-a700: #F2AFFF;


